.load {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: white;
  z-index: 9999;
}

.load div {
  width: 25px;
  height: 25px;
  background-color: #9604c6;
  border-radius: 50%;
  margin: 0 5px;
  animation-name: up-and-down;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.load .two {
  animation-delay: 0.3s;
}

.load .three {
  animation-delay: 0.6s;
}

@keyframes up-and-down {
  to {
    opacity: 0.2;
    transform: translateY(-20px);
  }
}
